<template>
  <div>
    <!--    MPD1 Meldungen-->
    <h2 v-html="$t('search_production_report_title')"></h2>
    <!--    Status von-->
    <v-select
      id="search_farm_status"
      v-model="SearchProductionReportStore.statusFrom"
      :label="$t('search_production_report_status_from')"
      :items="productionFormStatus"
      :item-title="this.$getLangKey()"
      item-value="name"
      clearable
      @keyup.enter="search"
    />
    <!--    Status bis -->
    <v-select
      id="search_farm_status"
      v-model="SearchProductionReportStore.statusTo"
      :label="$t('search_production_report_status_until')"
      :items="productionFormStatus"
      :item-title="this.$getLangKey()"
      item-value="name"
      clearable
      @keyup.enter="search"
    />
    <!-- Korrektur -->
    <v-select
      id="subsidy_production_version"
      v-model="SearchProductionReportStore.correction"
      :label="$t('subsidy_production_version')"
      :placeholder="$t('search_production_report_version_all')"
      :items="correction"
      clearable
      @keyup.enter="search"
    />
    <!-- Abschlussmonat von -->
    <DbmMonthPicker v-model="SearchProductionReportStore.validFrom" :dateLabel="$t('search_production_report_valid_from')" :clearable="true" />
    <!-- Abschlussmonat bis -->
    <DbmMonthPicker
      v-model="SearchProductionReportStore.validUntil"
      :dateLabel="$t('search_production_report_valid_until')"
      :clearable="true"
      returnType="lastOfMonth"
    />
    <!-- Tierart -->
    <v-select
      id="animalType"
      required
      v-model="SearchProductionReportStore.animalTypeId"
      :items="animalTypesProduction"
      item-value="id"
      :item-title="this.$getLangKey()"
      :label="$t('search_production_report_animalTypes')"
      clearable
      @keyup.enter="search"
    ></v-select>
  </div>
</template>

<script lang="ts">
import { mapGetters, mapActions } from 'vuex'
import baseSearchVue from './baseSearch.vue'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import { useAnimalTypesStore } from '@/store/enums/AnimalTypesStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchProductionReport',
  extends: baseSearchVue,
  data() {
    return {
      correction: [
        { title: this.$t('search_production_report_version_yes'), value: true },
        { title: this.$t('search_production_report_version_no'), value: false }
      ]
    }
  },
  computed: {
    animalTypesProduction() {
      return useAnimalTypesStore().AnimalTypesProduction
    },
    ...mapGetters('ProductionFormStatusStore', ['productionFormStatus']),
    SearchProductionReportStore() {
      return this.$store.state.SearchProductionReportStore
    }
  },
  components: {
    DbmMonthPicker: DbmMonthPicker
  },
  methods: {
    ...mapActions('ProductionFormStatusStore', ['fetchProductionFormStatus']),
    reset() {
      this.resetFields(this.SearchProductionReportStore)
    }
  },
  mounted() {
    useAnimalTypesStore().fetchAnimalTypesProduction()
    this.fetchProductionFormStatus()
    this.initSearchStoreFromQuery(this.SearchProductionReportStore, this.$route.query)
  }
})
</script>
